<template>
  <div>
    <list-template
        ref="table"
        :current-page="page"
        :loading="loading"
        :search-config="searchConfig"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        is-download
        @onChangePage="changeCurrentPage"
        @onDownload="download"
        @onHandle="tableHandle"
        @onSearch="onSearch"
        @onReset="onReset"
    >
    </list-template>
    <el-Dialog :title="`${itemData.student_name}的辅导后评价`" :visible.sync="dialogVisible" width="80%" @close="dialogVisible = false">
      <div v-if="comment" style="min-height: 120rem;display: flex;align-items: center;">{{ comment }}</div>
      <t-result v-else type="empty"></t-result>
    </el-Dialog>
  </div>
</template>
<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import ListTemplate from "@/components/pages/List.vue"
import TResult from "@/components/Result.vue";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {TResult, ListTemplate},
  computed: {
    ...mapState(['page']),
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      loading: true,
      dialogVisible: false,
      search: null,
      comment: '',
      itemData: {},
      total: 0,
      searchConfig: [
        {
          prop: 'school_id', tag: "select", placeholder: '筛选校区', options: [], label: 'name', value: 'id',
          change: (val,search) =>{
            search.grade_id = ''
            search.class_id = ''
            this.$_axios2.get('api/common/school', {params: {level: 2,school_id: val}}).then(res => {
              let data = res.data.data
              this.searchConfig[1].options = data.action_grade
              this.searchConfig[2].options = data.teaching_data
            })
          }
        },
        {
          prop: 'grade_id', tag: "select", placeholder: '筛选年级', options: [], label: 'name', value: 'id',
          change: (val,search) =>{
            search.class_id = ''
            if (search?.school_id)
              this.$_axios2.get('api/common/school', {params: {level: 2,school_id: search.school_id,grade_id: val}}).then(res => {
                let data = res.data.data
                this.searchConfig[2].options = data.teaching_data
              })
            else
              this.$_axios2.get('api/common/school', {params: {level: 2,grade_id: val}}).then(res => {
                let data = res.data.data
                this.searchConfig[2].options = data.teaching_data
              })
          }
        },
        {
          prop: 'class_id', tag: "select", placeholder: '筛选班级', options: [], label: 'name', value: 'id',
        },
        {
          prop: 'teacher_score_at',
          tag: 'datePicker',
          default: [],
          property: {
            size: 'small',
            style: 'margin-right:20rem;',
            type: 'daterange',
            valueFormat: 'yyyy-MM-dd',
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期'
          }
        },
        {
          prop: 'subject_id', tag: "cascader", placeholder: '筛选学科', options: [],
        },
        {prop: 'teacher_name', placeholder: '搜索教师姓名'},
        {prop: 'student_name', placeholder: '搜索学生姓名'},
        {
          prop: 'score', tag: "select", placeholder: '筛选评分', options: [{label: '1分', value: 1}, {label: '2分', value: 2}, {label: '3分', value: 3}, {label: '4分', value: 4}, {label: '5分', value: 5}]
        },
      ],
      tableData: [],
      tableConfig: [
        {prop: 'student_name', label: '学生姓名'},
        {prop: 'student_no', label: '学生学号'},
        {prop: 'teacher_name', label: '辅导老师'},
        {prop: 'subject_name', label: '辅导学科'},
        {prop: 'teacher_score_at', label: '提交日期', width: 180},
        {
          prop: 'score', label: '学生评分', render: (row) => {
            return `${row.score !== 0 ? row.score + '分' : '未评价'}`
          }
        },
        {prop: 'class_name', label: '教学班'},
        {prop: 'grade_name', label: '年级'},
        {prop: 'school_name', label: '学校'},
        {prop: 'handle', label: '操作', width: '80rem', handle: true, buttons: [{ type:"view", text:"查看" }]}
      ]
    }
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.onSearch()
    this.$_axios2.get('api/common/school', {params: {level: 2}}).then(res => {
      let data = res.data.data
      this.searchConfig[0].options = data.school
    })
    this.$_axios2.get('api/common/subject',{params:{level:3}}).then(res => {
      this.searchConfig[4].options = JSON.parse(
          JSON.stringify(res.data.data)
              .replace(/id/g, 'value')
              .replace(/subject_name/g, 'label')
              .replace(/children/g, 'children')
      )
      this.$forceUpdate()
    })
  },
  methods: {
    ...mapMutations(['setPage']),
    // 获取列表基础数据
    getData() {
      this.loading = true
      let params = this.search ? JSON.parse(JSON.stringify(this.search)) : {}
      if (params.teacher_score_at && params) {
        params.teacher_score_at = params.teacher_score_at.join('~')
      }
      if (params.subject_id && params) {
        params.subject_id = params.subject_id.pop()
      }
      this.$_axios2.get('api/coach-record/stu-scores', {params: {...params, page: this.page}}).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(() => (this.loading = false))
    },
    onSearch(val) {
      this.search = val
      this.getData()
    },
    changeCurrentPage(e) {
      this.setPage(e)
      this.getData()
    },
    download() {
      let params = this.search ? JSON.parse(JSON.stringify(this.search)) : {}
      if (params.teacher_score_at && params) {
        params.teacher_score_at = params.teacher_score_at.join('~')
      }
      if (params.subject_id && params) {
        params.subject_id = params.subject_id.pop()
      }
      this.$_axios2.get('api/coach-record/stu-scores-export', {params: {...params}}).then(res => {
        this.$tools.download('学生评价', res.data)
      })
    },
    tableHandle(row) {
      this.itemData = row
      this.comment = ''
      this.$_axios2.get('api/coach-record/stu-score-detail', {params: {id: row.id}}).then(res => {
        if (res.data.data.comment) this.comment = res.data.data.comment
      })
      this.dialogVisible = true
    },
    onReset() {
      this.searchConfig[0].options = []
      this.searchConfig[1].options = []
      this.searchConfig[2].options = []
      this.$_axios2.get('api/common/school', {params: {level: 2}}).then(res => {
        let data = res.data.data
        this.searchConfig[0].options = data.school
      })
    },
  }
}
</script>

<style scoped></style>
